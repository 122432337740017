import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import {
  Stack,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Header = ({ name }) => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);
  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      justifyContent="space-between"
      alignItems={{ base: "stretch", md: "center" }}
      w="full"
    >
      <Button
        leftIcon={<ArrowLeftIcon />}
        variant="outline"
        rounded="full"
        onClick={goBack}
      >
        Go BACK
      </Button>
      <Breadcrumb
        spacing={2}
        separator={<ArrowRightIcon size={18} color="gray.500" />}
        color="gray.500"
        fontWeight="semibold"
      >
        <BreadcrumbItem>
          <BreadcrumbLink>Home</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink>Venues</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink>Glamping</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink color="brand.500">{name}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </Stack>
  );
};

export default Header;
