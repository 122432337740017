import React, { useEffect, useState } from "react";
import EmptyView from "../../components/common/EmptyView";
import FilterPanel from "../../components/FiltersDemo/FilterPanel";
import List from "../../components/FiltersDemo/List";
import SearchBar from "../../components/FiltersDemo/SearchBar";
//import { dataList } from "../../constants";
import "./styles.css";

import { useCollection } from "../../hooks/useCollection";

import Footer from "../../components/Footer";
import Menu from "../../components/Menu";

const FiltersDemo = () => {
  const { documents, error } = useCollection("operators");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedRating, setSelectedRating] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState([10, 15000]);

  const [activities, setActivities] = useState([
    { id: 1, checked: false, label: "Hiking" },
    { id: 2, checked: false, label: "Kayaking" },
    { id: 3, checked: false, label: "Other" },
  ]);

  const [list, setList] = useState(documents); //dataList;
  const [resultsFound, setResultsFound] = useState(true);
  const [searchInput, setSearchInput] = useState("");

  const handleSelectCategory = (event, value) =>
    !value ? null : setSelectedCategory(value);

  const handleSelectRating = (event, value) =>
    !value ? null : setSelectedRating(value);

  const handleChangeChecked = (id) => {
    const activitiesStateList = activities;
    const changeCheckedActivities = activitiesStateList.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setActivities(changeCheckedActivities);
  };

  const handleChangePrice = (event, value) => {
    setSelectedPrice(value);
  };

  const applyFilters = () => {
    let updatedList = documents; //dataList;

    // Rating Filter
    if (selectedRating) {
      updatedList = updatedList.filter(
        (item) => parseInt(item.star) === parseInt(selectedRating)
      );
    }

    // Category Filter
    if (selectedCategory) {
      updatedList = updatedList.filter(
        (item) => item.category === selectedCategory
      );
    }

    // Activity Filter
    const activitiesChecked = activities
      .filter((item) => item.checked)
      .map((item) => item.label.toLowerCase());

    if (activitiesChecked.length) {
      updatedList = updatedList.filter((item) =>
        activitiesChecked.includes(item.activity)
      );
    }

    // Search Filter
    if (searchInput) {
      updatedList = updatedList.filter((item) =>
        item && item.locationName
          ? item.locationName
              .toLowerCase()
              .search(searchInput.toLowerCase().trim()) !== -1
          : true
      );
    }

    // Price Filter
    const minPrice = selectedPrice[0];
    const maxPrice = selectedPrice[1];

    updatedList = updatedList
      ? updatedList.filter((item) =>
          item.pricePointsLow && item.pricePointsHigh
            ? item.pricePointsLow >= minPrice &&
              item.pricePointsHigh <= maxPrice
            : true
        )
      : undefined;

    setList(updatedList);

    updatedList && updatedList.length
      ? setResultsFound(true)
      : setResultsFound(false);
  };

  useEffect(() => {
    applyFilters();
  }, [
    selectedRating,
    selectedCategory,
    activities,
    searchInput,
    selectedPrice,
  ]);

  return (
    <>
      <Menu />

      <section
        className="header2 travelm4_header2 cid-t1xda5z7Lx"
        id="header2-1e"
      >
        <div
          className="mbr-overlay"
          style={{ opacity: 0.4, backgroundColor: "rgb(0, 0, 0)" }}
        ></div>
        <div className="container align-center">
          <div className="row justify-content-center">
            <div className="mbr-white col-lg-8 col-md-10">
              <h1 className="mbr-section-title mbr-bold align-left mbr-white pb-1 mbr-fonts-style display-1">
                All Venues Available
              </h1>
              <p className="mbr-text align-left pb-4 mbr-fonts-style display-7">
                Create a better workplace…That allows more authentic connection,
                work/life balance, improves wellbeing, and advances equality —
                ultimately leading to sustainably increased innovation and
                superior business outcomes.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="home">
        {/* Search Bar */}
        <SearchBar
          value={searchInput}
          changeInput={(e) => setSearchInput(e.target.value)}
        />
        <div className="home_panelList-wrap">
          {/* Filter Panel */}
          <div className="home_panel-wrap">
            <FilterPanel
              selectedCategory={selectedCategory}
              selectCategory={handleSelectCategory}
              selectedRating={selectedRating}
              selectedPrice={selectedPrice}
              selectRating={handleSelectRating}
              activities={activities}
              changeChecked={handleChangeChecked}
              changePrice={handleChangePrice}
            />
          </div>
          {/* List & Empty View */}
          <div className="home_list-wrap">
            {list && resultsFound ? (
              <List list={list} linkTo="venues" />
            ) : (
              <EmptyView />
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default FiltersDemo;
