import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

export default function VenuesList({ venuesList }) {
  /*
  const services = [
    ...new Set(
      venuesList.map((venueItem) =>
        venueItem.services ? venueItem.services : ""
      )
    ),
  ].sort();

  const fAndBs = [
    ...new Set(
      venuesList.map((venueItem) => (venueItem.fAndB ? venueItem.fAndB : ""))
    ),
  ].sort();

  const stars = [
    ...new Set(
      venuesList.map((venueItem) => (venueItem.stars ? venueItem.stars : ""))
    ),
  ].sort();*/

  const currencyFormat = (value) => {
    if (value && value.trim() !== "") {
      return value;
    } else {
      return "$";
    }
  };

  return (
    <>
      {venuesList ? (
        venuesList.map((v, index) => (
          <section
            key={index}
            className="features7 travelm4_features7 cid-t32974UZxx"
            id="features7-2f"
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <h2 className="mbr-section-title title pb-2 mb-1 mbr-bold mbr-fonts-style align-left display-5">
                    {v.locationName}
                  </h2>
                </div>
              </div>
              <div className="row row-content justify-content-center">
                <div className="card p-3 col-12 col-md-12">
                  <div className="row card-wrapper">
                    <div className="col-md-4 card-img">
                      <div className="price-box">
                        <h5 className="price mbr-fonts-style mbr-bold display-4">
                          {currencyFormat(v.pricePointsLow)} -{" "}
                          {currencyFormat(v.pricePointsHigh)}
                        </h5>
                      </div>
                      <img
                        src={
                          v.pictures
                            ? v.pictures[0].src
                            : "assets/images/01.jpg"
                        }
                        title=""
                        alt=""
                      />{" "}
                      {/*"assets/images/01.jpg"*/}
                    </div>
                    <div className="card-box col-md-8">
                      <h4 className="card-title mbr-fonts-style pb-1 mbr-bold display-5">
                        {v.operatorName}
                      </h4>
                      <p className="mbr-text mbr-fonts-style mbr-normal display-4">
                        Accomodation Type: {v.accommodationType}.<br />
                        <p>{JSON.stringify(v)}</p>
                        <Link to="/venue">View More</Link>
                      </p>

                      <div className="ico-line line-box">
                        <div className="ico-line">
                          <div
                            className="px-1 pb-3 mbr-iconfont mbr-iconfont-social mbri-star"
                            style={{
                              color: "rgb(30, 198, 182)",
                              fill: "rgb(30, 198, 182)",
                            }}
                            media-simple="true"
                          ></div>
                          <div
                            className="px-1 pb-3 mbr-iconfont mbr-iconfont-social mbri-star"
                            style={{
                              color: "rgb(30, 198, 182)",
                              fill: "rgb(30, 198, 182)",
                            }}
                            media-simple="true"
                          ></div>
                          <div
                            className="px-1 pb-3 mbr-iconfont mbr-iconfont-social mbri-star"
                            style={{
                              color: "rgb(30, 198, 182)",
                              fill: "rgb(30, 198, 182)",
                            }}
                            media-simple="true"
                          ></div>
                          <div
                            className="px-1 pb-3 mbr-iconfont mbr-iconfont-social mbri-star"
                            style={{
                              color: "rgb(158, 158, 158)",
                              fill: "rgb(158, 158, 158)",
                            }}
                            media-simple="true"
                          ></div>
                          <div
                            className="px-1 pb-3 pr-2 mbr-iconfont mbr-iconfont-social mbri-star"
                            style={{
                              color: "rgb(158, 158, 158)",
                              fill: "rgb(158, 158, 158)",
                            }}
                            media-simple="true"
                          ></div>
                          <h3 className="rev mbr-fonts-style mbr-bold display-4">
                            4 review
                          </h3>
                        </div>

                        <div className="ico-line px-4">
                          <div
                            className="mbr-iconfont mbr-iconfont-social mbri-pin"
                            style={{
                              color: "rgb(0, 0, 0)",
                              fill: "rgb(0, 0, 0)",
                            }}
                            media-simple="true"
                          ></div>
                          <h3 className="rev pl-2 mbr-fonts-style mbr-bold display-4">
                            {v.address}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))
      ) : (
        <div>No Results</div>
      )}
    </>
  );
}
