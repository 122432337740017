import {
  HStack,
  VStack,
  Heading,
  Text,
  Avatar,
  Divider,
  Icon,
  AvatarBadge,
  SimpleGrid,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  Button,
  GridItem,
  Stack,
} from "@chakra-ui/react";
import { StarIcon, ArrowDownIcon } from "@chakra-ui/icons";
import IconLabel from "./icon-label";

import { currencyFormat, numberFormat } from "../../services/GeneralFunctions";

const testText = () => {
  return (
    <>
      <Text>
        Our property prevails in simplicity. It possess a large living area with
        big movable windows , in front and on the sides, which provides the
        magical view on Sava river even when you are relaxing inside.
      </Text>
      <Text>
        Our location - behind the Golf club Belgrade in Ada, 15 min drive from
        the city center, will not leave you out from the city's vibrant life.
      </Text>
      <Text>
        <strong>The space</strong>
        <br />
        We didnt let any walls or corridors stand in the way between you and the
        nature, so as soon as you open the front door, you will feel the
        positive energy of the water, flow through your body.
      </Text>
      <Text>
        <strong>Guest access</strong>
        <br />
        During the day, around 8 people are allowed to be in the house. Its
        perfect for small events such as birthdays, bachelorette parties, team
        buidings. 4 guests can sleep over.
      </Text>
    </>
  );
};

const ListingDetails = ({
  locationName,
  operatorName,
  pricePointsLow,
  pricePointsHigh,
  stars,
  venueDescription,
}) => {
  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      spacing={8}
      alignItems="flex-start"
    >
      <VStack spacing={6} alignItems="flex-start">
        <VStack spacing={2} alignItems="flex-start">
          <Heading size="lg">All about {locationName}</Heading>
          <HStack spacing={2}>
            <Text color="gray.500">Operator: </Text>
            <Avatar src="" name={operatorName} size="xs" />
            <Text color="brand.500">{operatorName}</Text>
          </HStack>
        </VStack>
        <Divider />
        <VStack alignItems="flex-start" spacing={4}>
          {venueDescription && venueDescription != "" ? (
            <div dangerouslySetInnerHTML={{ __html: venueDescription }} />
          ) : (
            testText()
          )}
        </VStack>
      </VStack>
      <VStack
        w="full"
        maxW={{ base: "auto", md: 80, lg: 96 }}
        flexShrink={0}
        p={6}
        spacing={6}
        justifyContent="stretch"
        alignItems="stretch"
        rounded="2xl"
        borderWidth={1}
        borderColor="gray.300"
      >
        <HStack justifyContent="space-between">
          <VStack>
            <HStack spacing={2} w="full">
              <Heading size="lg">
                {currencyFormat(pricePointsLow, 0)}-
                {currencyFormat(pricePointsHigh, 0)}
              </Heading>
              <Text color="gray.500">/ night</Text>
            </HStack>
            <IconLabel
              icon={<Icon fontSize={24} as={StarIcon} color="yellow.400" />}
              separator={
                <Text color="gray.500" fontWeight="bold">
                  ·
                </Text>
              }
            >
              <Text size="md">{numberFormat(stars)}</Text>
              <Text size="md"></Text>
            </IconLabel>
          </VStack>
          <Avatar src="" name={operatorName} size="lg">
            <AvatarBadge boxSize="1.38em" bg="green.500" />
          </Avatar>
        </HStack>
        <SimpleGrid
          columns={2}
          rounded="lg"
          borderWidth={1}
          borderColor="gray.300"
          p={3}
          gap={3}
        >
          <FormControl>
            <FormLabel
              fontWeight="bold"
              textTransform="uppercase"
              fontSize="xs"
            >
              Check-in
            </FormLabel>
            <Menu>
              <MenuButton
                textAlign="start"
                as={Button}
                variant="outline"
                fontSize="sm"
                w="full"
                pl={3}
                pr={2}
                rightIcon={<ArrowDownIcon />}
              >
                09/03/2021
              </MenuButton>
            </Menu>
          </FormControl>
          <FormControl>
            <FormLabel
              fontWeight="bold"
              textTransform="uppercase"
              fontSize="xs"
            >
              Check-out
            </FormLabel>
            <Menu>
              <MenuButton
                textAlign="start"
                as={Button}
                variant="outline"
                fontSize="sm"
                w="full"
                pl={3}
                pr={2}
                rightIcon={<ArrowDownIcon />}
              >
                09/10/2021
              </MenuButton>
            </Menu>
          </FormControl>
          <GridItem colSpan={2}>
            <FormControl>
              <FormLabel
                fontWeight="bold"
                textTransform="uppercase"
                fontSize="xs"
              >
                Guest
              </FormLabel>
              <Menu>
                <MenuButton
                  as={Button}
                  textAlign="start"
                  variant="outline"
                  fontSize="sm"
                  rightIcon={<ArrowDownIcon />}
                  w="full"
                >
                  1 Adult
                </MenuButton>
              </Menu>
            </FormControl>
          </GridItem>
        </SimpleGrid>
        <VStack spacing={2}>
          <Button colorScheme="brand" size="lg" w="full">
            Reserve
          </Button>
          <Text color="gray.500">You won’t be charged yet</Text>
        </VStack>
        <VStack spacing={3} alignItems="stretch">
          <HStack justifyContent="space-between">
            <Text>$98-294 x 7 nights</Text>
            <Text>$686-2,058</Text>
          </HStack>
          <HStack justifyContent="space-between">
            <Text>Food and Beverage</Text>
            <Text color="green.500">$100-250</Text>
          </HStack>
          <HStack justifyContent="space-between">
            <Text>Meeting Space</Text>
            <Text>$30-75</Text>
          </HStack>
          <HStack justifyContent="space-between">
            <Text>Meeting Space</Text>
            <Text>$30-75</Text>
          </HStack>
          <HStack justifyContent="space-between">
            <Text>Activities &amp; Excursions</Text>
            <Text>$100-500</Text>
          </HStack>
          <HStack justifyContent="space-between">
            <Text>Transport</Text>
            <Text>$20-100</Text>
          </HStack>
          {/* 
         
Inclusions: Accomodation ($200, 350, 500), Food ($100, 140, 250), Meeting SPace ($30, 53, 75), Activities ($100, 250, 500), AirFare (300, 300, 300 or 600 inter), Transort (20, 50 100)
Results: Low, Mid, High 
          */}
          <HStack justifyContent="space-between">
            <Text>Service fee</Text>
            <Text>$97</Text>
          </HStack>
          <Divider />
          <HStack justifyContent="space-between" fontWeight="bold">
            <Text>Total</Text>
            <Text>$1,063-3,349</Text>
          </HStack>
        </VStack>
      </VStack>
    </Stack>
  );
};

export default ListingDetails;
