import { useNavigate, Link } from "react-router-dom";
import Footer from "./Footer";
import Menu from "./Menu";

const Home = () => {
  return (
    <>
      <Menu />

      <section
        className="header1 travelm4_header1 cid-t1oThrZu0g mbr-fullscreen"
        id="header1-9"
      >
        <div
          className="mbr-overlay"
          style={{ opacity: "0.3", backgroundColor: "rgb(0, 0, 0)" }}
        ></div>

        <div className="container align-center">
          <div className="row justify-content-start">
            <div className="mbr-white col-lg-6 col-md-8">
              <h1 className="mbr-section-title mbr-bold align-left mbr-white pb-1 mbr-fonts-style display-1">
                Plan your dream corporate retreat... <br />
                in nature&nbsp;
              </h1>
              <p className="mbr-text align-left pb-4 mbr-fonts-style display-7">
                Whether it’s to reconnect your team in-person after 2+ years of
                being apart&nbsp;or building teams to thrive in this new hybrid
                work environment, you can do so with Regenr8's help in
                a&nbsp;more sustainable, inclusive and healthful way.
              </p>
              <div className="mbr-section-btn align-left">
                <a
                  className="btn btn-md btn-primary display-4"
                  href="contactus"
                >
                  <span className="mbri-arrow-next mbr-iconfont mbr-iconfont-btn"></span>
                  Plan Trips
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="features2 travelm4_features2 cid-t1oQMZYxfN"
        id="features2-4"
      >
        <div className="container">
          <h2 className="mbr-section-title pb-2 mbr-bold mbr-fonts-style align-left display-2">
            Why Choose Us
          </h2>

          <h3 className="mbr-section-sub-title pb-4 mbr-normal mbr-fonts-style align-left display-4">
            Destinations for offsite meetings
          </h3>

          <div className="media-container-row">
            <div className="card p-3 col-12 col-md-6 border-r col-lg-4">
              <div className="card-img pb-3">
                <span
                  className="mbr-iconfont mbri-home"
                  style={{
                    color: "rgb(255, 74, 82)",
                    fill: "rgb(255, 74, 82)",
                  }}
                ></span>
              </div>
              <div className="card-box">
                <h4 className="count card-title mbr-fonts-style mbr-white mbr-bold align-center display-5">
                  The Ideal Place and Activities to Support Your Business
                  Objectives
                </h4>
                <p className="mbr-text mbr-fonts-style mbr-white mbr-normal align-center display-4">
                  Select glamping sites, eco resorts, regenerative farms, or
                  build a bespoke experience in a unique nature-based setting
                  that will inspire and energize your team. The goal of each
                  selected venue and the related experiences is to support the
                  “why” of the retreat or event.
                </p>
              </div>
            </div>

            <div className="card p-3 col-12 col-md-6 border-r col-lg-4">
              <div className="card-img pb-3">
                <span
                  className="mbr-iconfont mbri-globe"
                  style={{
                    color: "rgb(255, 74, 82)",
                    fill: "rgb(255, 74, 82)",
                  }}
                ></span>
              </div>
              <div className="card-box">
                <h4 className="count card-title mbr-fonts-style mbr-white mbr-bold align-center display-5">
                  Regenerative Venues and Operators
                </h4>
                <p className="mbr-text mbr-fonts-style mbr-white mbr-normal align-center display-4">
                  Bizglamp takes an Un-Vegas approach to business retreats and
                  teambuilding. We've selected venues for their capacity to
                  inspire, motivate, and engage. We also interview resort
                  operators and staff to ensure they are caring, courteous,
                  adaptive and constantly available to support the client.
                </p>
              </div>
            </div>

            <div className="card p-3 col-12 col-md-6 border-r col-lg-4">
              <div className="card-img pb-3">
                <span
                  className="mbr-iconfont mbri-protect"
                  style={{
                    color: "rgb(255, 74, 82)",
                    fill: "rgb(255, 74, 82)",
                  }}
                ></span>
              </div>
              <div className="card-box">
                <h4 className="count card-title mbr-fonts-style mbr-white mbr-bold align-center display-5">
                  Professional Consulting and Concierge Services
                </h4>
                <p className="mbr-text mbr-fonts-style mbr-white mbr-normal align-center display-4">
                  Bizglamp is owned and operated by professionals with decades
                  of experience helping clients motivate individuals and
                  strengthen teams to achieve business success. Our concierge
                  team works with each client and venue operator to tailor the
                  retreat to exacting standards and adjust to changes in
                  weather, travel schedules or other variables.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="features5 travelm4_features5 cid-t1oQJDPpGz"
        id="features5-3"
      >
        <div className="container-fluid align-center">
          <div className="row">
            <div className="mbr-white col-lg-6 col-md-12 card1">
              <div
                className="mbr-overlay"
                style={{ opacity: 0.3, backgroundColor: "rgb(0, 0, 0)" }}
              ></div>
              <h1 className="mbr-section-title mbr-bold align-left mbr-white pb-1 mbr-fonts-style display-2">
                <div>Country</div>
              </h1>
              <p className="mbr-text align-left pb-4 mbr-fonts-style display-7">
                <br />
                <br />
                <br />
                <br />
              </p>
            </div>
            <div className="mbr-white col-lg-6 col-md-12 card2">
              <div
                className="mbr-overlay"
                style={{ opacity: 0.3, backgroundColor: "rgb(0, 0, 0)" }}
              ></div>
              <h1 className="mbr-section-title mbr-bold align-left mbr-white pb-1 mbr-fonts-style display-2">
                <div>Mountain</div>
              </h1>
              <p className="mbr-text align-left pb-4 mbr-fonts-style display-7">
                <br />
                <br />
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="article2 cid-t1oSxBAdVt" id="article2-8">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="mbr-section-subtitle mbr-fonts-style display-4">
                EXPERIENTIAL TRAVEL
              </h3>
              <h3 className="mbr-section-title mbr-fonts-style display-2">
                &nbsp;Engagement is something for everyone.
                <br />
                &nbsp;BizGlamp is just what you needed
              </h3>
            </div>
          </div>
        </div>
      </section>

      <section
        className="features04 politicsm4_features04 cid-t1oS3nBBMw"
        id="features04-6"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="card p-3 col-12 col-md-6 col-lg-4">
              <div className="card-wrapper card1 align-left">
                <div className="card-ico pb-4">
                  <span className="mbr-iconfont card-icon mobi-mbri-contact-form mobi-mbri"></span>
                </div>
                <div className="card-box">
                  <h4 className="card-title mbr-fonts-style display-7">
                    Retreats that Promote Wellbeing and Heal the Planet
                  </h4>
                  <div className="line-wrap">
                    <div className="line"></div>
                  </div>
                  <p className="mbr-text mbr-fonts-style display-4">
                    We collaborate with venue operators and our clients to
                    ensure each Bizglamp retreat provides health-promoting
                    activity and is sustainably operated. Energy, water, food,
                    transport and materials are managed to reduce carbon
                    footprint, eliminate landfill waste, and support
                    biodiversity.
                  </p>
                  <div className="card-link pt-2">
                    <a href="contactus">
                      <span className="mbr-iconfont link-ico mobi-mbri-arrow-next mobi-mbri"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="card p-3 col-12 col-md-6 col-lg-4">
              <div className="card-wrapper card2 align-left">
                <div className="card-ico pb-4 align-left">
                  <span className="mbr-iconfont card-icon mobi-mbri-users mobi-mbri"></span>
                </div>
                <div className="card-box">
                  <h4 className="card-title mbr-fonts-style display-7">
                    Inclusive Teambuilding
                  </h4>
                  <div className="line-wrap">
                    <div className="line"></div>
                  </div>
                  <p className="mbr-text mbr-fonts-style display-4">
                    Bizglamp goes beyond venue sourcing to be your partner
                    creating inclusive experiences that engage all attendees. We
                    even offer hybrid-event teambuilding to include
                    participation with individuals who are unable to physically
                    attend.
                  </p>
                  <div className="card-link pt-2">
                    <a href="contactus">
                      <span className="mbr-iconfont link-ico mobi-mbri-arrow-next mobi-mbri"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="card p-3 col-12 col-md-6 col-lg-4">
              <div className="card-wrapper card3 align-left">
                <div className="card-ico pb-4 align-left">
                  <span className="mbr-iconfont card-icon mobi-mbri-globe mobi-mbri"></span>
                </div>
                <div className="card-box">
                  <h4 className="card-title mbr-fonts-style display-7">
                    Sustainable Catering and Open-Air Meeting Emphasis
                  </h4>
                  <div className="line-wrap">
                    <div className="line"></div>
                  </div>
                  <p className="mbr-text mbr-fonts-style display-4">
                    We work with property operators and other partners offer
                    full catering services with a bias towards organically
                    sourced farm and sea-to-table meals. Our concierges
                    proactively address meal preferences and dietary
                    restrictions. Meeting spaces are available in open-air
                    settings as well as larger enclosed rooms with natural
                    ventilation.
                  </p>
                  <div className="card-link pt-2">
                    <a href="contactus">
                      <span className="mbr-iconfont link-ico mobi-mbri-arrow-next mobi-mbri"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="card p-3 col-12 col-md-6 col-lg-4">
              <div className="card-wrapper card4 align-left">
                <div className="card-ico pb-4 align-left">
                  <span className="mbr-iconfont card-icon mobi-mbri-like mobi-mbri"></span>
                </div>
                <div className="card-box">
                  <h4 className="card-title mbr-fonts-style display-7">
                    Common Space for Inclusive Recreation, Relaxation and
                    Bonding
                  </h4>
                  <div className="line-wrap">
                    <div className="line"></div>
                  </div>
                  <p className="mbr-text mbr-fonts-style display-4">
                    These places and spaces are designed to bring people
                    together in ways that stimulate the mind, body and spirit.
                    Bizglamp also ensures all gathering areas are inclusive to
                    not restrict access and participation to team members with
                    physical or other accessibility challenges.
                  </p>
                  <div className="card-link pt-2">
                    <a href="contactus">
                      <span className="mbr-iconfont link-ico mobi-mbri-arrow-next mobi-mbri"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="card p-3 col-12 col-md-6 col-lg-4">
              <div className="card-wrapper card5 align-left">
                <div className="card-ico pb-4 align-left">
                  <span className="mbr-iconfont card-icon mobi-mbri-map-pin mobi-mbri"></span>
                </div>
                <div className="card-box">
                  <h4 className="card-title mbr-fonts-style display-7">
                    Nearby Activities that are Engaging, Eco-Friendly, Healthy
                    and Inclusive
                  </h4>
                  <div className="line-wrap">
                    <div className="line"></div>
                  </div>
                  <p className="mbr-text mbr-fonts-style display-4">
                    Bizglamp works with the venue operators, local excursion
                    providers and other partners to ensure there are plenty of
                    fun, convenient, healthy and enriching activities for
                    everyone. From e-bikes to spa experiences; from yoga to
                    kayaking; from wine tastings to outdoor music...Bizglamp
                    helps keep everyone engaged and invigorated.
                  </p>
                  <div className="card-link pt-2">
                    <a href="contactus">
                      <span className="mbr-iconfont link-ico mobi-mbri-arrow-next mobi-mbri"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="card p-3 col-12 col-md-6 col-lg-4">
              <div className="card-wrapper card6 align-left">
                <div className="card-ico pb-4 align-left">
                  <span className="mbr-iconfont card-icon mobi-mbri-drag-n-drop mobi-mbri"></span>
                </div>
                <div className="card-box">
                  <h4 className="card-title mbr-fonts-style display-7">
                    Nearby Airport and Convenient Transport
                  </h4>
                  <div className="line-wrap">
                    <div className="line"></div>
                  </div>
                  <p className="mbr-text mbr-fonts-style display-4">
                    Our goal is to enable teams to not sweat the transportation
                    issues. We strive to make the venues easily drivable for as
                    many attendees as possible If not drivable you are generally
                    within an hour of a major airport, so no one gets stuck in
                    transit. Once onsite, we help ensure convenient transport to
                    all excursions and activities.
                  </p>
                  <div className="card-link pt-2">
                    <a href="contactus">
                      <span className="mbr-iconfont link-ico mobi-mbri-arrow-next mobi-mbri"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="card p-3 col-12 col-md-6 col-lg-4">
              <div className="card-wrapper card6 align-left">
                <div className="card-ico pb-4 align-left">
                  <span className="mbr-iconfont card-icon mobi-mbri-wifi mobi-mbri"></span>
                </div>
                <div className="card-box">
                  <h4 className="card-title mbr-fonts-style display-7">
                    Fast and Reliable WiFi
                  </h4>
                  <div className="line-wrap">
                    <div className="line"></div>
                  </div>
                  <p className="mbr-text mbr-fonts-style display-4">
                    Bizglamp understands most retreats and meetings require fast
                    and consistent WiFi wherever you are onsite. Our venue
                    vetting includes guaranteed standards for connectivity and
                    ensuring availability of equipment needed to conduct
                    effective meetings and presentations.
                  </p>
                  <div className="card-link pt-2">
                    <a href="contactus">
                      <span className="mbr-iconfont link-ico mobi-mbri-arrow-next mobi-mbri"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="features19 businessm4_features19 cid-t1oSa5xFYB"
        id="features19-7"
      >
        <div className="container align-left">
          <div className="row ">
            <div className="col-lg-8 col-md-12">
              <div className="img-wrap">
                <img
                  src="assets/images/computer-screenshot-1200x695.png"
                  alt=""
                />
              </div>
            </div>
            <div className="align-self-center mbr-black col-lg-4 col-md-12">
              <div className="card-wrap">
                <h3 className="mbr-section-subtitle align-left mbr-light mbr-fonts-style display-5">
                  Our team is eager to help you safely reconnect in-person.{" "}
                  <br />
                  Get in touch to see BizGlamp in action!
                </h3>
                <div className="mbr-section-btn align-left pt-3">
                  <a className="btn btn-info btn-md display-4" href="contactus">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="header2 travelm4_header2 cid-t1oQz9dp7V"
        id="header2-1"
      >
        <div
          className="mbr-overlay"
          style={{ opacity: 0.4, backgroundColor: "rgb(0, 0, 0)" }}
        ></div>

        <div className="container align-center">
          <div className="row justify-content-center">
            <div className="mbr-white col-lg-8 col-md-10">
              <h2 className="mbr-section-title mbr-fonts-style display-2">
                BizGlamp by regenr8
              </h2>
              <div className="social-list">
                <a href="https://regenr8.ai" target="_blank">
                  <span className="mbr-iconfont mbr-iconfont-social  socicon">
                    <img
                      className="mbr-iconfont socicon"
                      style={{ width: "13.8rem" }}
                      src="assets/images/r8-2.png"
                    />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;
