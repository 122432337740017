import React from "react";
import { Link } from "react-router-dom";
import ListItem from "./ListItem";
import "./styles.css";

const List = ({ list, linkTo }) => (
  <div className="list-wrap">
    {list.map((item) => (
      <Link key={item.id} to={`/${linkTo}/${item.id}`}>
        <ListItem item={item} />
      </Link>
    ))}
  </div>
);

export default List;
