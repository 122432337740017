import React from "react";
import "./styles.css";

const EmptyView = () => (
  <div className="emptyView-wrap">
    <img src="assets/images/gif/empty.gif" alt="" />
  </div>
);

export default EmptyView;
