import {
  AspectRatio,
  GridItem,
  Image,
  Grid,
  Text,
  Button,
} from "@chakra-ui/react";

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

function getMod(number1, number2) {
  return number1 - number2 * Math.floor(number1 / number2);
}

const Gallery = ({ pictures }) => {
  const getPicture = (pictures, index) => {
    return pictures && pictures.length > index
      ? pictures[index].src
      : "/assets/images/01.jpg";
  };
  let a = getRandomInt(3);
  let b = getMod(a + 1, 3);
  let c = getMod(b + 1, 3);
  const first = getPicture(pictures, a);
  const nextTwo = [getPicture(pictures, b), getPicture(pictures, c)];

  return (
    <Grid
      templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(4, 1fr)" }}
      templateRows={{ base: "repeat(4, 1fr)", md: "repeat(3, 1fr)" }}
      gap={3}
    >
      <GridItem colSpan={3} rowSpan={3}>
        <AspectRatio ratio={1}>
          <Image
            objectFit="cover"
            rounded={{ base: "xl", md: "3xl" }}
            src={first}
            alt="Beach House Photo"
          />
        </AspectRatio>
      </GridItem>
      {nextTwo.map((image, index) => (
        <GridItem
          key={image}
          colSpan={1}
          rowSpan={1}
          colStart={{ base: index + 1, md: 4 }}
          rowStart={{ base: 4, md: index + 1 }}
        >
          <AspectRatio ratio={1}>
            <Image
              rounded={{ base: "xl", md: "3xl" }}
              src={image}
              alt="Beach House Photo"
            />
          </AspectRatio>
        </GridItem>
      ))}
      <GridItem
        colSpan={1}
        rowSpan={1}
        colStart={{ base: 3, md: 4 }}
        rowStart={{ base: 4, md: 3 }}
      >
        <AspectRatio ratio={1}>
          <Button
            alignItems="center"
            justifyContent="center"
            rounded={{ base: "xl", md: "3xl" }}
            bg="gray.100"
          >
            <Text
              whiteSpace="break-spaces"
              fontSize={{ base: "xs", md: "xl" }}
              color="gray.500"
            >
              View {pictures?.length - 3}+ photos
            </Text>
          </Button>
        </AspectRatio>
      </GridItem>
    </Grid>
  );
};

export default Gallery;
