import { useNavigate, Link } from "react-router-dom";
import Footer from "./Footer";
import Menu from "./Menu";

const ContactUs = () => {


    return (
        <>

            <Menu />

            <section className="header2 travelm4_header2 cid-t1xda5z7Lx" id="header2-1e">



                <div className="mbr-overlay" style={{ opacity: 0.4, backgroundColor: 'rgb(0, 0, 0)' }}>
                </div>

                <div className="container align-center">
                    <div className="row justify-content-center">
                        <div className="mbr-white col-lg-8 col-md-10">
                            <h1 className="mbr-section-title mbr-bold align-left mbr-white pb-1 mbr-fonts-style display-1">Get In Touch With Us</h1>
                            <p className="mbr-text align-left pb-4 mbr-fonts-style display-7">
                                </p>

                        </div>
                    </div>
                </div>

            </section>

            <section className="form2 travelm4_form2 form cid-t1xdd8TlNW" id="form2-1f">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-8 col-sm-12 align-left card">


                            <div data-form-type="formoid">
                                <form action="https://mobirise.eu/" method="POST" className="mbr-form form-with-styler" data-form-title="Mobirise Form"><input type="hidden" name="email" data-form-email="true" value="i9DOklY37QgNoa7Fs1e8SrQuQWFFlS3ogMTuTJx+yzb0ed/LkLElPVnZSa0/8FyCOEJpl1VsnxlSPIPHczxgfeiySjVd8y0oIr9tOkYZquXCoBFe/JMFBGUTel4FCmVY.apNLMXLYCyYAN7mR3YKXzIGYS+Q9zOMzVjVmwa8IIirdzenc1vCFMqmdZ0g/XHV2nvhORNVVqI0bh/r/mbEYz/yRRvAMcEvu8HUH0bD0bJFhzEpOd15NEoKmXjrlLsXl" />
                                    <div className="row">
                                        <div hidden="hidden" data-form-alert="" className="alert alert-success col-12">Thanks for filling
                                            out the form! <br />Our concierge team will be in touch soon.</div>
                                        <div hidden="hidden" data-form-alert-danger="" className="alert alert-danger col-12"></div>
                                    </div>
                                    <div className="dragArea row">
                                        <div className="col-md-12 form-group" data-for="name">
                                            <label for="name-form2-1f" className="form-control-label mbr-fonts-style display-4">Full Name</label>
                                            <input type="text" name="name" placeholder="Your Name" data-form-field="Name" required="required" className="form-control display-7" id="name-form2-1f" />
                                        </div>
                                        <div className="col-md-12 form-group" data-for="email">
                                            <label for="email-form2-1f" className="form-control-label mbr-fonts-style display-4">Email Address</label>
                                            <input type="email" name="email" placeholder="Your Email" data-form-field="Email" required="required" className="form-control display-7" id="email-form2-1f" />
                                        </div>
                                        <div data-for="phone" className="col-md-12 form-group">
                                            <label for="phone-form2-1f" className="form-control-label mbr-fonts-style display-4">Phone Number</label>
                                            <input type="tel" name="phone" placeholder="Your Phone Number" data-form-field="Phone" className="form-control display-7" id="phone-form2-1f" />
                                        </div>
                                        <div data-for="interested" className="col-md-12 form-group">
                                            <label for="interested-form2-1f" className="form-control-label mbr-fonts-style display-4">Event Geographical Region</label>
                                            <input type="tel" name="interested" placeholder="Bay Area, Seattle ..." data-form-field="Interes" className="form-control display-7" id="interested-form2-1f" />
                                        </div>
                                        <div data-for="person" className="col-md-12 form-group">
                                            <label for="person-form2-1f" className="form-control-label mbr-fonts-style display-4">Number of Person</label>
                                            <input type="tel" name="person" placeholder="How many people will travel" data-form-field="Persons" className="form-control display-7" id="person-form2-1f" />
                                        </div>
                                        <div className="col-md-12 input-group-btn">
                                            <button type="submit" className="btn btn-form btn-primary display-4">Request Quote</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="image1 travelm4_image1 cid-t1xdfe885S" id="image1-1g">



                <div className="mbr-overlay" style={{ opacity: 0, backgroundColor: 'rgb(0, 0, 0)' }}>
                </div>

                <div className="container">


                </div>
            </section>

            <Footer />
        </>
    )
}

export default ContactUs
