import {
  Heading,
  HStack,
  Stack,
  Icon,
  IconButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  Search2Icon,
  StarIcon,
  CheckCircleIcon,
  LockIcon,
  ChatIcon,
  ExternalLinkIcon,
} from "@chakra-ui/icons";

import IconLabel from "./icon-label";
import { numberFormat } from "../../services/GeneralFunctions";

const TitleDetails = ({ name, units, unitTypes, address, stars }) => {
  return (
    <VStack spacing={4} alignItems="stretch">
      <Stack
        direction={{ base: "column", md: "row" }}
        justifyContent="space-between"
      >
        <Heading fontSize={{ base: "4xl", lg: "5xl" }}>{name}</Heading>
        <HStack spacing={4}>
          <IconButton
            variant="pill"
            aria-label="Get location"
            icon={<Search2Icon />}
          />
          <IconButton aria-label="Share" variant="pill" icon={<ChatIcon />} />
          <IconButton
            aria-label="Add to favorites"
            variant="pill"
            icon={<CheckCircleIcon />}
          />
          <IconButton
            aria-label="More options"
            variant="pill"
            icon={<ExternalLinkIcon />}
          />
        </HStack>
      </Stack>
      <Stack
        direction={{ base: "column", md: "row" }}
        justifyContent="space-between"
        spacing={6}
        w={{ base: "auto", md: "fit-content" }}
      >
        <IconLabel
          icon={<Icon fontSize={24} as={StarIcon} color="yellow.400" />}
          separator={
            <Text color="gray.500" fontWeight="bold">
              ·
            </Text>
          }
        >
          <Text size="md">{numberFormat(stars)}</Text>
          <Text size="md">{/*26 reviews*/}</Text>
        </IconLabel>
        <IconLabel
          icon={<Icon fontSize={24} as={Search2Icon} color="gray.500" />}
        >
          <Text size="md">{address}</Text>
        </IconLabel>
        <IconLabel
          icon={<Icon fontSize={24} as={LockIcon} color="gray.500" />}
          separator={
            <Text color="gray.500" fontWeight="bold">
              ·
            </Text>
          }
        >
          <Text size="md">{numberFormat(units)} units</Text>
          <Text size="md">{unitTypes}</Text>
        </IconLabel>
      </Stack>
    </VStack>
  );
};

export default TitleDetails;
