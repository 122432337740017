import React from "react";
import "./styles.css";

const ListItem = ({
  item: {
    pictures,
    locationName,
    pricePointsLow,
    pricePointsHigh,
    serviceTime,
    stars,
  },
}) => (
  <div className="listItem-wrap">
    <img src={pictures ? pictures[0].src : "assets/images/01.jpg"} alt="" />
    <header>
      <h4>{locationName}</h4>
      {stars ? <span>🌟{stars}</span> : <span></span>}
    </header>
    <footer>
      <p>
        <b>{serviceTime}</b> <span> Price Range: {pricePointsLow}</span>
      </p>
      <p>
        <b>{pricePointsHigh}</b>
      </p>
    </footer>
  </div>
);

export default ListItem;
