const currencyFormat = (value, decimal) => {
  let num = !isNaN(Number(value)) ? Number(value) : 0;
  return "$" + num.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
const numberFormat = (value) => {
  let num = !isNaN(Number(value)) ? Number(value) : 0;
  return num.toLocaleString();
};

export { currencyFormat, numberFormat };
