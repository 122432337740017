import { Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

//pages
import Home from "./components/Home";
import ContactUs from "./components/ContactUs";
import Intake from "./components/Intake";
import Venues from "./components/venues/Venues";
import IntakeAdmin from "./components/IntakeAdmin";
//navigation
import Register from "./components/Register";
import Login from "./components/Login";
import Missing from "./components/Missing";
import Unauthorized from "./components/Unauthorized";
//test
import VenueDemo from "./components/venuesDemo";
import FilterDemo from "./pages/FiltersDemo";
import IntakeTest from "./components/IntakeTest";
import Editor from "./components/Editor";
import Admin from "./components/Admin";
import Lounge from "./components/Lounge";
import LinkPage from "./components/LinkPage";
import TempHome from "./components/TempHome";
import Demo from "./components/Wizard/Demo";
//routing
import RequireAuth from "./components/RequireAuth";
import Layout from "./components/Layout";

import ComingSoon from "./pages/ComingSoon";
import VenueView from "./components/venues/VenueView";
import FiltersDemo from "./pages/FiltersDemo";

import theme from "./styles/theme.js";

const ROLES = {
  User: 2001,
  Editor: 1984,
  Admin: 5150,
  SuperAdmin: 9991,
};

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route index element={<Home />} />
          <Route path="ContactUs" element={<ContactUs />} />
          <Route path="Intake" element={<Intake />} />
          <Route path="Venues" element={<Venues />} />
          <Route path="Venues/:venueId" element={<VenueView />} />
          <Route path="VenueDemo" element={<VenueDemo />} />
          {/* public navigation routes */}
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          {/* public test pages */}
          <Route path="IntakeTest" element={<IntakeTest />} />
          <Route path="linkPage" element={<LinkPage />} />
          <Route path="Demo" element={<Demo />} />
          {/* we want to protect these routes */}
          <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} />}>
            <Route path="IntakeAdmin" element={<IntakeAdmin />} />
          </Route>
          {/* private test pages */}
          <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} />}>
            <Route path="TempHome" element={<TempHome />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} />}>
            <Route path="filtersDemo" element={<FiltersDemo />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Editor]} />}>
            <Route path="editor" element={<Editor />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="admin" element={<Admin />} />
          </Route>
          <Route
            element={<RequireAuth allowedRoles={[ROLES.Editor, ROLES.Admin]} />}
          >
            <Route path="lounge" element={<Lounge />} />
          </Route>
          {/* catch all */}
          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
    </ChakraProvider>
  );
}

export default App;
