import React from 'react'

export default function Menu() {
    return (
        <>
            <section className="menu menu1 travelm4_menu1 cid-t1oQvBaIe3" id="menu1-0">
                <nav className="navbar navbar-dropdown align-items-center navbar-fixed-top navbar-toggleable-sm bg-color transparent">
                    <div className="menu-bottom" style={{alignContent: 'center'}}>
                        <div className="menu-logo">
                            <div className="navbar-brand">
                                <span className="navbar-logo">
                                    <a href="\">
                                        <img src="assets/images/bizglamp-logo-381x128.png" alt="" style={{ height: '6.2rem' }} />
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav nav-dropdown js-float-line" data-app-modern-menu="true"><li className="nav-item">
                                <a className="nav-link link mbr-black text-danger display-4" href="\">
                                    Home</a>
                            </li><li className="nav-item"><a className="nav-link link mbr-black text-danger display-4" href="contactus">Plan Trips</a></li></ul>
                        </div>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <div className="hamburger">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>

                        <div className="menu-content-right">
                            <div className="navbar-buttons mbr-section-btn"><a className="btn btn-md btn-primary display-4" href="contactus">
                                <span className="mbri-arrow-next mbr-iconfont mbr-iconfont-btn"></span>
                                Plan Trips</a></div>
                        </div>
                    </div>
                </nav>
            </section>
        </>
    )
}
