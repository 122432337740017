import React from "react";
import { useParams } from "react-router-dom";
import { Container, Divider, VStack } from "@chakra-ui/react";

import Gallery from "../venuesDemo/gallery";
import Header from "../venuesDemo/header";
import ListingDetails from "../venuesDemo/listing-details";
import ThingsToKnow from "../venuesDemo/things-to-know";
import TitleDetails from "../venuesDemo/title";
import WhatsIncluded from "../venuesDemo/whats-included";

import { useDocument } from "../../hooks/useDocument";

import Footer from "../../components/Footer";
import Menu from "../../components/Menu";
import Loading from "../common/Loading";

export default function VenueView() {
  const params = useParams();
  const { document, error } = useDocument("operators", params.venueId);

  if (error) {
    return <div className="error">{error}</div>;
  }
  if (!document) {
    return <Loading />;
  }

  return (
    <>
      <Container maxW="container.xl" py={10}>
        <VStack spacing={8} alignItems="stretch">
          <Header name={document.locationName} />
          <TitleDetails
            name={document.locationName}
            units={document.units}
            unitTypes={document.unitTypes}
            address={document.address}
            stars={document.stars}
          />
          <Gallery pictures={document.pictures} />
          <ListingDetails
            locationName={document.locationName}
            operatorName={document.operatorName}
            pricePointsLow={document.pricePointsLow}
            pricePointsHigh={document.pricePointsHigh}
            stars={document.stars}
            venueDescription={document.venueDescription}
          />
          <WhatsIncluded />
          <Divider />
          <ThingsToKnow />
        </VStack>
      </Container>
    </>
  );
}
