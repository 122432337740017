import React from 'react'

import { useCollection } from '../hooks/useCollection'

import Footer from "./Footer";
import Menu from "./Menu";
import IntakeList from './IntakeList'

export default function IntakeTest() {
    const { isPending, documents, error } = useCollection('intake')
   // console.log('IntakeTest', documents);

    return (
        <>
        
            <Menu />

            <section className="header2 travelm4_header2 cid-t1xda5z7Lx" id="header2-1e">
                <div className="mbr-overlay" style={{ opacity: 0.4, backgroundColor: 'rgb(0, 0, 0)' }}>
                </div>
                <div className="container align-center">
                    <div className="row justify-content-center">
                        <div className="mbr-white col-lg-8 col-md-10">
                            <h1 className="mbr-section-title mbr-bold align-left mbr-white pb-1 mbr-fonts-style display-1">Get In Touch With Us</h1>
                            <p className="mbr-text align-left pb-4 mbr-fonts-style display-7">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nisl elit, varius ut magna vel,
                                tincidunt iaculis sem. Interdum
                                et malesuada fames ac ante ipsum primis in faucibus.</p>

                        </div>
                    </div>
                </div>

            </section>
            {documents ? <IntakeList intakeList={documents} /> : <h1>loading...</h1>}
            

            <Footer />
        </>
    )
}
