import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";

const EventOverviewForm = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (value) => setValue(value);

  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <div>
        <p>Step 1 of 3</p>
      </div>
      <Controller
        control={control}
        name="eventName"
        rules={{ required: "this field is required." }}
        render={({ field }) => (
          <TextField
            id="event-name"
            label="Event Name"
            variant="outlined"
            placeholder="Enter Your Event Name"
            fullWidth
            margin="normal"
            {...field}
            error={Boolean(errors?.eventName)}
            helperText={errors.eventName?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="groupSize"
        rules={{ required: "this field is required." }}
        render={({ field }) => (
          <NumberInput
            defaultValue={15}
            min={1}
            max={300}
            {...field}
            keepWithinRange={true}
            clampValueOnBlur={false}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        )}
      />
      <Controller
        control={control}
        name="contactName"
        rules={{ required: "this field is required." }}
        render={({ field }) => (
          <TextField
            id="contact-name"
            label="Contact Name"
            variant="outlined"
            placeholder="Enter Your Name"
            fullWidth
            margin="normal"
            {...field}
            error={Boolean(errors?.contactName)}
            helperText={errors.contactName?.message}
          />
        )}
      />
    </>
  );
};

export default EventOverviewForm;
