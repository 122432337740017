export const categoryList = [
  {
    id: 1,
    value: "mountain",
    label: "🏔 Mountain",
  },
  {
    id: 2,
    value: "beach",
    label: "🏝 Beach",
  },
  {
    id: 3,
    value: "Forest",
    label: "🛤 Forest",
  },
  {
    id: 4,
    value: "desert",
    label: "🏜 Desert",
  },
];

export const ratingList = [
  {
    id: 1,
    value: "1",
    label: "1🌟",
  },
  {
    id: 2,
    value: "2",
    label: "2🌟",
  },
  {
    id: 3,
    value: "3",
    label: "3🌟",
  },
  {
    id: 4,
    value: "4",
    label: "4🌟",
  },
  {
    id: 5,
    value: "5",
    label: "5🌟",
  },
];
