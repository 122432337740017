import React from "react";
import { TextField } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import { Input, FormLabel } from "@chakra-ui/react";

/*
-------------------------------
Dates // Component with mulitple options in order
Flexible // NumberOfNights, Weekdays, Weekends, Mix. Months
Room: Single Occupancy, Double Occupancy, No Occupancy Preference

--Atmosphere: Tell us more about your goals and teh atmosphere you have in mind
Goals: Work, Fun, both
Atmosphere: BEach, City, Mountain, Country, Woods?
*/

const EventDetailsForm = () => {
  const { control } = useFormContext();
  return (
    <>
      <FormLabel>Planned Event Start Date</FormLabel>
      <Controller
        control={control}
        name="startDate"
        render={({ field }) => <Input type="date" id="startDate" {...field} />}
      />
      <FormLabel>Planned Event Start Date</FormLabel>
      <Controller
        control={control}
        name="endDate"
        render={({ field }) => <Input type="date" id="endDate" {...field} />}
      />
      <Controller
        control={control}
        name="address1"
        render={({ field }) => (
          <TextField
            id="address1"
            label="Address 1"
            variant="outlined"
            placeholder="Enter Your Address 1"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="address2"
        render={({ field }) => (
          <TextField
            id="address2"
            label="Address 2"
            variant="outlined"
            placeholder="Enter Your Address 2"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="country"
        render={({ field }) => (
          <TextField
            id="country"
            label="Country"
            variant="outlined"
            placeholder="Enter Your Country Name"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />
    </>
  );
};

export default EventDetailsForm;
