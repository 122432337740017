import React from "react";
import {
    TextField,
} from "@material-ui/core";
import {
    Controller,
    useFormContext,
} from "react-hook-form";

const ContactForm = () => {

    const { control,
        formState: { errors }, } = useFormContext();
    return (
        <>
            <Controller
                control={control}
                name="emailAddress"
                //rules={{ required: "this field is required." }}
                render={({ field }) => (
                    <TextField
                        id="email"
                        label="E-mail"
                        variant="outlined"
                        placeholder="Enter Your E-mail Address"
                        fullWidth
                        margin="normal"
                        {...field}
                        error={Boolean(errors?.emailAddress)}
                        helperText={errors.emailAddress?.message}
                    />
                )}
            />

            <Controller
                control={control}
                name="phoneNumber"
                render={({ field }) => (
                    <TextField
                        id="phone-number"
                        label="Phone Number"
                        variant="outlined"
                        placeholder="Enter Your Phone Number"
                        fullWidth
                        margin="normal"
                        {...field}
                    />
                )}
            />
            <Controller
                control={control}
                name="alternatePhone"
                render={({ field }) => (
                    <TextField
                        id="alternate-phone"
                        label="Alternate Phone"
                        variant="outlined"
                        placeholder="Enter Your Alternate Phone"
                        fullWidth
                        margin="normal"
                        {...field}
                    />
                )}
            />
        </>
    );
};

export default ContactForm