import React, { useState } from "react";
import {
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, FormProvider } from "react-hook-form";
import { useFirestore } from "../../hooks/useFirestore";
//stepper forms
import EventOverviewForm from "./EventOverviewForm";
import ContactForm from "./ContactForm";
import EventDetailsForm from "./EventDetailsForm";
import PaymentForm from "./PaymentForm";
import Steps from "./Steps";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getStepContent(step) {
  switch (step) {
    case 0:
      return <EventOverviewForm />;
    case 1:
      return <EventDetailsForm />;
    case 2:
      return <ContactForm />;
    case 3:
      return <PaymentForm />;
    default:
      return "unknown step";
  }
}
/* Fields
--General Info: Tell us about your group and retreat requiremnts
EventName
GroupSize
-------------------------------
Dates // Component with mulitple options in order
Flexible // NumberOfNights, Weekdays, Weekends, Mix. Months
Room: Single Occupancy, Double Occupancy, No Occupancy Preference

--Atmosphere: Tell us more about your goals and teh atmosphere you have in mind
Goals: Work, Fun, both
Atmosphere: BEach, City, Mountain, Country, Woods?

-------------------------------
--Budget and Extras: Tell us more about your companyus budget and logistcal requirements

--Budget Estimator: Use this simple calculator to evaluate your retreat budget
NumberOfAttendees
NumberOfNights
InternationTravel?
Inclusions: Accomodation ($200, 350, 500), Food ($100, 140, 250), Meeting SPace ($30, 53, 75), Activities ($100, 250, 500), AirFare (300, 300, 300 or 600 inter), Transort (20, 50 100)
Results: Low, Mid, High

-------------------------------
--Contact Info
ContactName
PhoneNumber
Email

####List of venues/ Request quote per item after sign up
--Filters
Distination: text/intellsense
Location Radius: location/intellsense. Within XXX miles
Group Size: numerical field
Atmosphere: Beafch, City, Mountain, Country -Checkboxes
Total Budget: Dollar
Budget Per Person: Dollar
Dates: number of nights and which months
Activities: Text/intellsense List
Room Preference/Type: Single, Double, NO prefernces

--Item Vierwe
Name
Location
---Venue Details
Number of Rooms: XX
Closest AIrport: SFO
Proximity to airpot: ZXX minutes
Acitivities: Tags----
Descritpion

Request a quote:
Contatct Venue:

Estimated total Veniue- Range...
Estimated Accomodation: Range...
Estimated F&B: Range...
Estimated Meeting Space: Range...z
*/
const LinaerStepper = () => {
  const classes = useStyles();
  const methods = useForm({
    defaultValues: {
      eventName: "",
      groupSize: 10,
      eventDates: [],
      roomOccupancyPreference: "",
      contactName: "",
      emailAddress: "",
      phoneNumber: "",
      alternatePhone: "",
      startDate: "",
      endDate: "",
      address1: "",
      address2: "",
      country: "",
      cardNumber: "",
      cardMonth: "",
      cardYear: "",
    },
  });
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const { addDocument, response } = useFirestore("intake");

  const isStepOptional = (step) => {
    return false && (step === 1 || step === 2); //all steps required for now
  };
  const isStepFalied = () => {
    return Boolean(Object.keys(methods.formState.errors).length);
  };
  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = (data) => {
    if (activeStep === Steps.length - 1) {
      //Intake complete. Save Request
      addDocument(data).then(() => {
        setActiveStep(activeStep + 1);
      });
    } else {
      //Move to next step
      setActiveStep(activeStep + 1);
      setSkippedSteps(
        skippedSteps.filter((skipItem) => skipItem !== activeStep)
      );
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    if (!isStepSkipped(activeStep)) {
      setSkippedSteps([...skippedSteps, activeStep]);
    }
    setActiveStep(activeStep + 1);
  };

  return (
    <div>
      <Stepper alternativeLabel activeStep={activeStep}>
        {Steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography
                variant="caption"
                align="center"
                style={{ display: "block" }}
              >
                optional
              </Typography>
            );
          }
          if (isStepFalied() && activeStep === index) {
            labelProps.error = true;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === Steps.length ? (
        <>
          {" "}
          <Typography variant="h3" align="center">
            Thank You, our concierge team will be in touch shortly!
          </Typography>
        </>
      ) : (
        <>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleNext)}>
              {getStepContent(activeStep)}

              <Button
                className={classes.button}
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                back
              </Button>
              {isStepOptional(activeStep) && (
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  onClick={handleSkip}
                >
                  skip
                </Button>
              )}
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                // onClick={handleNext}
                type="submit"
              >
                {activeStep === Steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </form>
          </FormProvider>
        </>
      )}
    </div>
  );
};

export default LinaerStepper;
