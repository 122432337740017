import { Heading, Center, Spinner, Flex } from "@chakra-ui/react";
import React from "react";

export default function Loading() {
  return (
    <>
      <Center bg="whilte" h="100%"></Center>

      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Flex direction="column" background="grey.100" p={12} rounded={6}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      </Flex>
    </>
  );
}
