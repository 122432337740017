import React from 'react'

export default function Footer() {
    return (
        <>
            <section className="footer1 travelm4_footer1 cid-t1oQAN6Jls" id="footer1-2">





                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <h3 className="mbr-text mbr-bold mbr-fonts-style group-title display-7">
                                BizGlamp</h3>

                            <p className="mbr-text mbr-normal py-2 links mbr-fonts-style display-4">
                                Retreats in Nature
                            </p>

                            <div className="navbar-brand">
                                <div className="logo">
                                    <a href="#">
                                        <img className="navbar-logo" src="assets/images/bizglamp-favicon-128x128.png" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <p className="mbr-text mb-0 mbr-fonts-style mbr-white align-center display-7">© Copyright 2022 <a href="https://regenr8.ai/" className="text-white" target="_blank">regenr8.ai</a> - All Rights Reserved</p>
                        </div>
{/*
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <h3 className="mbr-text mbr-bold mbr-fonts-style group-title display-7">
                                Contacts
                            </h3>

                            <div className="mbr-row items mbr-white">




                                <div className="list-item mbr-col-lg-12 mbr-col-md-12 mbr-col-sm-12">
                                    <span className="mbr-iconfont listico mbri-tablet-vertical" style={{ color: 'rgb(255, 255, 255)', fill: 'rgb(255, 255, 255)' }}></span>
                                    <h5 className="mbr-fonts-style text2 display-4">
                                        123-456-78901</h5>
                                </div><div className="list-item mbr-col-lg-12 mbr-col-md-12 mbr-col-sm-12">
                                    <span className="mbr-iconfont listico mbri-pin" style={{ color: 'rgb(255, 255, 255)', fill: 'rgb(255, 255, 255)' }}></span>
                                    <h5 className="mbr-fonts-style text2 display-4">tbd</h5>
                                </div><div className="list-item mbr-col-lg-12 mbr-col-md-12 mbr-col-sm-12">
                                    <span className="mbr-iconfont listico mbri-clock" style={{ color: 'rgb(255, 255, 255)', fill: 'rgb(255, 255, 255)' }}></span>
                                    <h5 className="mbr-fonts-style text2 display-4">Mon -
                                        Sat 8.00 - 18.00 PST</h5>
                                </div></div>

                            <div className="social-list py-4">
                                <div className="soc-item">
                                    <a href="#" target="_blank">
                                        <div className="mbr-iconfont mbr-iconfont-social socicon-twitter socicon" style={{ color: 'rgb(255, 255, 255)', fill: 'rgb(255, 255, 255)' }} media-simple="true"></div>
                                    </a>
                                </div>
                                <div className="soc-item">
                                    <a href="#" target="_blank">
                                        <div className="mbr-iconfont mbr-iconfont-social socicon-facebook socicon" style={{ color: 'rgb(255, 255, 255)', fill: 'rgb(255, 255, 255)' }} media-simple="true"></div>
                                    </a>
                                </div>




                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12 col-sm-12 img-list">
                            <h3 className="mbr-text mbr-bold mbr-fonts-style group-title display-7">
                                Sample Retreats</h3>
                            <img className="tips" src="assets/images/01.jpg" alt="" />
                            <img className="tips" src="assets/images/02.jpg" alt="" />
                            <img className="tips" src="assets/images/03.jpg" alt="" />
                            <img className="tips" src="assets/images/04.jpg" alt="" />
                            <img className="tips" src="assets/images/05.jpg" alt="" />
                            <img className="tips" src="assets/images/06.jpg" alt="" />
                        </div> */}
                    </div>
                </div>
            </section>

        </>
    )
}
