import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/database";

/*import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/database';*/

const firebaseConfig = {
  apiKey: "AIzaSyCbZ3ubSY5JPZ0csGToo-ofbNwVhea99Po",
  authDomain: "glampere-8ee87.firebaseapp.com",
  databaseURL: "https://glampere-8ee87-default-rtdb.firebaseio.com",
  projectId: "glampere-8ee87",
  storageBucket: "glampere-8ee87.appspot.com",
  messagingSenderId: "549611999310",
  appId: "1:549611999310:web:0cefad7a46ed6bec5c731f",
};

// init firebase
firebase.initializeApp(firebaseConfig);

// init services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectStorage = firebase.storage();
const projectDatabase = firebase.database();

// timestamp
const timestamp = firebase.firestore.Timestamp;

export {
  projectFirestore,
  projectAuth,
  timestamp,
  projectStorage,
  projectDatabase,
  firebaseConfig,
};
