import { useRef, useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Image,
  Text,
  HStack,
  Box,
} from "@chakra-ui/react";

import useAuth from "../hooks/useAuth";
import { useCollection } from "../hooks/useCollection";
import { useNavigate, useLocation } from "react-router-dom";

import { projectAuth, projectFirestore } from "../firebase/config";

const Login = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const { documents, error } = useCollection("users");

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await projectAuth.signInWithEmailAndPassword(user, pwd);

      // update online status
      //const documentRef = projectFirestore.collection('users').doc(res.user.uid)
      const document = documents.filter((doc) => doc.uid === res.user.uid);

      const roles = document && document.length > 0 ? document[0].roles : [];

      setAuth({ user, pwd, roles });
      setUser("");
      setPwd("");
      navigate(from, { replace: true });
    } catch (err) {
      setErrMsg(err.message);
      errRef.current.focus();
    }
  };

  return (
    <>
      <section>
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
      </section>

      <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
        <Flex p={8} flex={1} align={"center"} justify={"center"}>
          <Stack spacing={4} w={"full"} maxW={"md"}>
            <HStack>
              <Box>
                <span className="navbar-logo">
                  <img
                    src="assets/images/bizglamp-favicon-128x128.png"
                    alt="bizglamp"
                    style={{ height: "3.8rem" }}
                  />
                </span>
              </Box>
              <Box>
                <span className="navbar-caption-wrap">
                  <a className="navbar-caption text-black display-2">
                    BizGlamp
                  </a>
                </span>
              </Box>
            </HStack>
            <Heading fontSize={"2xl"}>Sign in to your account</Heading>
            <FormControl>
              <FormLabel>Email address</FormLabel>
              <Input
                placeholder="email@domain.com"
                variant="filled"
                mb={3}
                type="email"
                id="username"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                required
              ></Input>
            </FormControl>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <Input
                placeholder="***********"
                variant="filled"
                mb={3}
                type="password"
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
              ></Input>
            </FormControl>
            <Stack spacing={6}>
              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Checkbox>Remember me</Checkbox>
                <Link color={"blue.500"}>Forgot password?</Link>
              </Stack>
              <Button
                colorScheme={"blue"}
                variant={"solid"}
                onClick={handleSubmit}
              >
                Sign in
              </Button>
            </Stack>{" "}
            <Stack pt={6}>
              <Text align={"center"}>
                BizGlamp by{" "}
                <Link
                  color={"blue.400"}
                  href="https://regenr8.ai/"
                  isExternal={true}
                >
                  Regenr8.ai
                </Link>
              </Text>
            </Stack>
          </Stack>
        </Flex>
        <Flex flex={1}>
          <Image
            alt={"Login Image"}
            objectFit={"cover"}
            src={"assets/images/loginbackground_blk.png"}
          />
        </Flex>
      </Stack>
    </>
  );
};

export default Login;
