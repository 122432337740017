import { Container, Divider, VStack } from "@chakra-ui/react";
import Gallery from "./gallery";
import Header from "./header";
import ListingDetails from "./listing-details";
import ThingsToKnow from "./things-to-know";
import TitleDetails from "./title";
import WhatsIncluded from "./whats-included";

const IndexPage = () => (
  <Container maxW="container.xl" py={10}>
    <VStack spacing={8} alignItems="stretch">
      <Header />
      <TitleDetails />
      <Gallery />
      <ListingDetails />
      <WhatsIncluded />
      <Divider />
      <ThingsToKnow />
    </VStack>
  </Container>
);

export default IndexPage;
