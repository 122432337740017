import { useNavigate, Link } from "react-router-dom";
import Footer from "./Footer";
import Menu from "./Menu";
import IntakeForm from "./IntakeWizard/IntakeForm";

const Intake = () => {
  return (
    <>
      <Menu />

      <section
        className="header2 travelm4_header2 cid-t1xda5z7Lx"
        id="header2-1e"
      >
        <div
          className="mbr-overlay"
          style={{ opacity: 0.4, backgroundColor: "rgb(0, 0, 0)" }}
        ></div>

        <div className="container align-center">
          <div className="row justify-content-center">
            <div className="mbr-white col-lg-8 col-md-10">
              <h1 className="mbr-section-title mbr-bold align-left mbr-white pb-1 mbr-fonts-style display-1">
                Plan your perfect retreat
              </h1>
              <p className="mbr-text align-left pb-4 mbr-fonts-style display-7">
                Let us help you put to together the perfect corporate retreat in
                nature.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="cid-t1xdd8TlNW" id="intake-1i">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-8 col-sm-12 align-left card">
              <IntakeForm />
            </div>
          </div>
        </div>
      </section>

      <section className="image1 travelm4_image1 cid-t1xdfe885S" id="image1-1g">
        <div
          className="mbr-overlay"
          style={{ opacity: 0, backgroundColor: "rgb(0, 0, 0)" }}
        ></div>

        <div className="container"></div>
      </section>

      <Footer />
    </>
  );
};

export default Intake;
